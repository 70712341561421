import cx from 'classnames'
import { Button } from 'components/ui/Button'
import { ImageWithFallback } from 'components/ui/Image'
import { Text } from 'components/ui/Text'
import { ROUTES } from 'constants/routes'
import { useTranslations } from 'next-intl'
import Link from 'next/link'

interface Props {
  imgUrl: string
  message: React.ReactNode
  className?: string
  showBackButton?: boolean
  imgSize?: number
}

export function Error(props: Props) {
  const t = useTranslations()
  const {
    message,
    imgSize = 100,
    className,
    imgUrl,
    showBackButton = false,
  } = props

  return (
    <div className={cx('flex flex-col items-center text-center', className)}>
      <ImageWithFallback
        width={imgSize}
        height={imgSize}
        fallback={imgUrl}
        src={imgUrl}
      />
      <Text textColor="800" className="mb-5 whitespace-pre-line">
        {message}
      </Text>
      <div className="space-y-2 w-full">
        <Button
          isRounded
          fullWidth
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('try_again')}
        </Button>
        {showBackButton ? (
          <Button
            as={Link}
            href={ROUTES.HOME}
            variant="outline"
            isRounded
            fullWidth
          >
            {t('back_to_home')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}
