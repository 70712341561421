import { Error } from 'components/common/Error'
import { Layout } from 'components/common/Layout'
import { useTranslations } from 'next-intl'

function ErrorPage() {
  const t = useTranslations()
  return (
    <Layout>
      <div className="absolute inset-0 flex items-center pt-5 pb-20 px-4">
        <Error
          className="max-w-xs mx-auto"
          imgUrl="/img/404.png"
          imgSize={140}
          message={t('404_message')}
          showBackButton
        />
      </div>
    </Layout>
  )
}

export default ErrorPage
